/**
 * Created by Carlo on 07/04/2016.
 */

STE.UI = new function() {

  this.selectorTables = function() {
    var $rows = $('.table-selector tbody tr').removeClass('selected');
    $rows.find('input[type=radio]:checked').parents('tr').addClass('selected');
    $rows.on('click', function() {
      var $this = $(this);
      $this.siblings('tr').removeClass('selected');
      var $input = $this.addClass('selected').find('input[type=radio]');

      if ($input.length > 0 && !$input.is(':disabled')) {
        $input.prop('checked', true).trigger('change');
      } else if ($input.is(':disabled') && $input.is('[data-not-enabled-message]')) {
        STE.UI.Popups.notification($input.data('not-enabled-message'), 'warning');
      }
    });
  };

  this.insideIFrame = function() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  this.scrollTo = function(selector, speed) {
    if (!speed) speed = 500;
    if (!(selector instanceof jQuery))
      selector = $(selector);
    return selector.each(function() {
      var $this = $(this);
      var parentInfo = STE.getIFrameContainerInfo();

      if (parentInfo) {
        setTimeout(function() {
          parentIFrame.scrollTo(0, parentInfo.offsetTop + $this.offset().top);
        }, 500);
      } else {
        $('body, html').animate({
          scrollTop: $this.offset().top
        }, speed);
      }
    });
  };

  var uiActions = {
    scrollTo: function(event) {
      event.preventDefault();
      var scroll = $(event.currentTarget).attr('href') || $(event.currentTarget).data('target') || '';
      STE.UI.scrollTo(scroll);
    },
    toggle: function(event) {
      event.preventDefault();
      $($(event.currentTarget).data('target')).slideToggle(300);
    },
    sort: function(event) {
      event.preventDefault();
      var toggler = $(event.currentTarget);

      var $togglers = $('[data-ui-action="sort"][data-container="' + toggler.data('container') + '"]');
      $togglers.each(function() {
        var $t = $(this);
        if ($t.find('.fas').length > 0) {
          var $icon = $t.find('.fas');
          $icon.removeClass('fa-sort-amount-down-alt').addClass('fa-sort');
          if ($t.data('sort-property') === $(toggler).data('sort-property')) {
            $icon.removeClass('fa-sort').addClass('fa-sort-amount-down-alt');
          }
        }
      });

      var container = $(toggler.data('container'));
      var items = container.find(toggler.data('items'));
      var property = toggler.data('sort-property');

      var activeElementSelector = toggler.data('sort-active-element');
      var activeElementLabel = toggler.data('sort-active-label');

      if (activeElementSelector && activeElementLabel) {
        $(activeElementSelector).text(activeElementLabel);
      }

      items.sort(function(a, b) {
        var type = $(a).attr('data-sort-' + property + '-type') || 'string';
        var valueA, valueB;
        valueA = $(a).attr('data-sort-' + property);
        valueB = $(b).attr('data-sort-' + property);
        if (type === 'number') {
          valueA = parseFloat(valueA);
          valueB = parseFloat(valueB);
        }

        return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
      }).each(function(idx, container) {
        $(container).parent().append(container);
      });
    },
    submit: function(event) {
      event.preventDefault();
      var $this = $(event.currentTarget);
      var $target = $($this.data('target'));
      $target.submit();
    },
    ajaxDialog: function(event) {
      event.preventDefault();
      var $this = $(event.currentTarget);
      var endpoint = $this.data('endpoint');
      var params = $this.data('params') ? JSON.parse($this.data('params')) : {};
      var id = $this.data('dialog-id') || '';

      var dialog = STE.UI.Popups.ajaxDialog(endpoint, params);
      /// se è specificato un id si memorizza il dialog
      // /nell'oggetto window in modo che possa essere modificato/chiuso
      if (id) {
        window['dialog_' + id] = dialog;
      }
    },
    popupWindow: function(event) {
      event.preventDefault();
      var $this = $(event.currentTarget);
      var url = $this.data('url') || $this.attr('href');
      var width = $this.data('width') || 800;
      var height = $this.data('height') || 600;
      var w = window.open(url, 'name', 'height=' + height + ',width=' + width);
      if (window.focus) {
        w.focus();
      }
    }
  };

  this.actions = function() {
    $('body').on('click', '[data-ui-action]', function() {
      var action = $(this).data('ui-action');
      if (action in uiActions) {
        uiActions[action].apply(this, arguments);
      }
    });

    /* funziona soltanto per elementi già presenti e non iniettati successivamente nel dom */
    $('[data-ui-action-alt]').each(function() {
      var evt = $(this).data('ui-event') || 'click';
      $(this).on(evt, function() {
        var action = $(this).data('ui-action-alt');
        if (action in uiActions) {
          uiActions[action].apply(this, arguments);
        }
      });
    });
  };

  this.inputHelp = function($input) {
    var $label = $('label[for="' + $input.attr('id') + '"]');
    if ($label.length === 0) return false;

    var help = $input.data('help');
    $label.prepend($('<i class="fa fa-fw fa-info-circle"></i>'));
    $label.attr('data-content', help).popover({placement: 'top', container: 'body', trigger: 'hover'});
  };

  this.inputGroupClick = function() {
    $('.input-group-addon').on('click', function() {
      $(this).next().focus();
    });
  };

  /**
   * Populates a linked dropdown with values obtained from an ajax call, based on target input's selected value.
   * Dropdowns can be cascading, i.e. there may be more than two chained dropdowns, each getting values from the other.
   * Attributes:
   *  - data-chaineddropdown: id of the linked dropdown
   *  - data-endpoint: endpoint of the ajax call
   *  - data-paramname: name of the parameter to attach to the ajax call, that will be valued with the input's value
   *  - data-with-[paramname]: additional value to send with the ajax call. Ex. data-with-affiliate_id="2"
   *  - data-withfield-[paramname]: selector of other fields to be sent in the ajax call. Ex. data-withfield-affiliate_id="#affiliate_id"
   *  - data-cancelifempty: cancels the ajax call if the current value is not set
   *  - data-method: http method for the ajax call (default get)
   *  - data-optionlabel: label for the empty option
   *  - data-value: original value for the linked dropdown. Its value will be set to this value (if present). It can be an array of selected values (for multiselect).
   */
  this.chainedDropdowns = function() {
    $('[data-chaineddropdown]').on('change', function() {
      var $this = $(this);

      var $chained = $('[id="' + $this.data('chaineddropdown') + '"]');
      var endpoint = $this.data('endpoint');
      if (!endpoint || $chained.length === 0) return false;

      var paramName = $this.data('paramname') || 'id';
      var cancelIfEmpty = $this.data('cancelifempty') || true;
      var method = $this.data('method') || 'get';
      var optionLabel = $this.data('optionlabel') || '';

      var valueField = $this.data('valuefield') || '';
      var textField = $this.data('textfield') || '';

      if ($this.val() === null || $this.val() === '' && cancelIfEmpty) {
        if (optionLabel !== '')
          $chained.html($('<option value="">' + optionLabel + '</option>'));
        return false;
      }

      var chainedValue = $chained.data('value') || [];
      if (!Array.isArray(chainedValue)) chainedValue = [chainedValue];
      chainedValue = $.map(chainedValue, String);

      var params = _parseWithParams($this);
      params[paramName] = $this.val();

      $.ajax({
        type: method,
        url: endpoint,
        data: params,
        success: function(data) {
          if (data) {
            var options = [];
            if (optionLabel !== '')
              options.push('<option value="">' + optionLabel + '</option>');

            var selected = false;
            $.each(data, function(index, item) {
              selected = chainedValue.indexOf(item[valueField]) >= 0;
              options.push('<option value="' + item[valueField] + '"' + (selected ? ' selected="selected"' : '') + '>' + item[textField] + '</option>');
            });
            $chained.html(options.join('')).change();
          }
        }
      });
    }).change();
  };

  this.mirrorFields = function() {
    $('[data-mirror]').on('blur', function() {
      var $this = $(this);
      var mirrorId = $this.data('mirror');
      var overwrite = $this.data('mirror-overwrite') || false;
      var $mirror = $(document.getElementById(mirrorId));

      if ($mirror.length > 0 && ((!overwrite && $mirror.val().trim() === '') || overwrite)) {
        $mirror.val($this.val());
      }
    });
  };

  this.dateRangePicker = function(selector, options) {
    var settings = {
      autoclose: true,
      language: STE.getLocale(),
      disableTouchKeyboard: true,
      format: 'dd/mm/yyyy'
    };

    return $(selector).datepicker($.extend(settings, options)).attr('autocomplete', 'off').on('changeDate', function(e) {
      var $picker = $(e.currentTarget);
      if ($picker.attr('data-datealt')) {
        var $alt = $('[name="' + $picker.data('datealt') + '"]');
        if (e.date) {
          $alt.val(moment(e.date).format('YYYY-MM-DD')).trigger('change');
        } else {
          $alt.val('');
        }
      }

      if ($picker.data('daterange') === 'start') {
        var $end = $($picker.attr('data-daterange-other') ? '#' + $picker.data('daterange-other') : '[data-daterange="end"]');
        if ($end.length > 0) {
          var endDate = $end.datepicker('getDate');
          if (endDate !== null && moment(e.date).isAfter(moment(endDate))) {
            $end.datepicker('setDate', e.date);
          }
          $end.datepicker('setStartDate', e.date);
        }
      }
    }).each(function() {
      var $picker = $(this);
      if ($picker.attr('data-datealt')) {
        var $field = $('[name="' + $picker.data('datealt') + '"]');
        if ($field.val() !== '') {
          var date = moment($field.val(), 'YYYY-MM-DD');
          if (date.isValid()) $picker.datepicker('setDate', date.toDate());
        }
      }
    }).on('change', function(e) {
      var $picker = $(e.target);
      if ($picker.val() === '' && $picker.attr('data-datealt')) {
        var $field = $('[name="' + $picker.data('datealt') + '"]');
        $field.val('');
      }
    });
  };

  var _removeValidationErrors = function($form) {
    $form.find('.error-summary,.error-message').remove();
    $form.find('.has-error').removeClass('has-error');
  };

  var _showValidationErrors = function($form, response) {
    var summary = '<div class="error-summary">';
    summary += '<ul>';
    var errors = [];
    var $first = null;
    $.each(response, function(name, msg) {
      if (name.indexOf('.') >= 0) {
        var dotted = name.split('.');
        var bracketed = dotted[0];
        for (var i = 1; i < dotted.length; i++) {
          bracketed += '[' + dotted[i] + ']';
        }
        name = bracketed;
      }

      errors.push('<li>' + msg + '</li>');
      var $element = $form.find('[name="' + name + '"], [name="' + name + '[]"]');
      if ($element.length > 0) {

        var $parent = $element.closest('.form-group,.checkbox,.radio');
        $parent.addClass('has-error');

        if ($first === null) {
          $first = $parent.first();
        }

        var $msg = $('<span />', {
          'class': 'help-block error-message' + ($form.hasClass('form-horizontal') ? ' text-right' : ''),
          'text': msg
        });
        if ($parent.is('.form-group')) {
          if ($parent.find('.error-message').length > 0) {
            $parent.find('.error-message').text(msg);
          } else {
            $msg.appendTo($parent);
          }
        }

        $element.on('change', function() {
          if ($(this).val() !== '') {
            $parent.removeClass('has-error');
            $msg.remove();
          }
        });
      }
    });

    /// rimuove i messaggi duplicati dal sommario degli errori
    errors = $.grep(errors, function(el, index) {
      return index === $.inArray(el, errors);
    });

    summary += errors.join('') + '</ul></div>';

    /*var parentInfo = STE.getIFrameContainerInfo();
    if (parentInfo) {
        parentIFrame.scrollTo(0, parentInfo.offsetTop);
    }*/
    STE.UI.Popups.alert(Lang.get('labels.generic_error_validation'), summary, 'danger', function() {
      STE.UI.scrollTo($first);
    });
  };

  this.asyncLoad = function($element) {
    var endpoint = $element.data('endpoint');
    var cache = $element.data('cache') || true;
    var timeout = $element.data('timeout') || 1000;
    var loader = $element.data('loader') || false;
    var params = JSON.parse($element.data('params') || '{}');
    var method = $element.data('method') || 'get';
    var replaceContainer = $element.data('replace') || false;
    var removeContainerIfEmpty = $element.data('remove-if-empty') || true;
    // se la risposta ajax è un oggetto complesso, prop è la chiave dell'oggetto che conterrà il contenuto
    // con cui popolare l'elemento
    var prop = $element.data('response-prop') || null;

    var loaderElement;
    if (loader) {
      loaderElement = $('<div class="async-loader"></div>').append('<i class="fa fa-spinner fa-spin fa-2x"></i>');
      $element.append(loaderElement);
    }

    //console.log(endpoint, params, method);

    setTimeout(function() {
      $.ajax({
        url: endpoint,
        cache: cache,
        data: params,
        type: method,
        success: function(data) {
          if (data) {
            var replace = data;
            if (prop && data[prop]) {
              replace = data[prop];
            }
            if (replaceContainer) {
              $element.replaceWith(replace);
            } else {
              if (loader) {
                loaderElement.remove();
              }
              $element.append(replace);
            }
          } else {
            if (removeContainerIfEmpty)
              $element.remove();
          }
        }
      });
    }, timeout);
  };

  this.asyncFormSubmit = function($form, config) {
    _removeValidationErrors($form);

    var $submit = $('[type="submit"]');

    var beforeSubmitFn = $form.data('beforesubmit') || undefined;
    if (beforeSubmitFn && typeof window[beforeSubmitFn] === 'function') {
      window[beforeSubmitFn]();
    }
    var afterSubmitFn = $form.data('aftersubmit') || undefined;

    var formData = $form.serializeArray();
    var method = $form.attr('method');
    var $spoofed = $form.find('input[name="_method"]');
    if ($spoofed.length > 0) // method spoofing
      method = $spoofed.val();

    $submit.button('loading');

    var $loadingImage = $form.attr('data-loading-image');

    var message = '<img src="' + $loadingImage + '" alt="" /><br /><div class="progress">' +
        '<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">';
    if ($form.attr('data-loading-text') !== '') {
      message += '<span class="hidden-xs">' + $form.attr('data-loading-text') + '<span class="dotdotdot"></span></span>';
    }
    message += '<span class="visible-xs">' + Lang.get('labels.loading_message_short') + '<span class="dotdotdot"></span></span>';
    message += '</div></div>';

    Pace.restart();
    Pace.track(function() {
      $.blockUI({
        message: message
      });
      $.ajax({
        type: method,
        url: $form.attr('action'),
        data: formData,
        success: function(data) {
          var context = data.context || 'notification';
          var alertType = data.type || 'success';
          var message = data.message || Lang.get('labels.generic_success_redirect');

          STE.UI.Popups.notify(context, alertType, message);

          if (config && config.onSuccess && typeof config.onSuccess === 'function') {
            config.onSuccess(data);
          }
        },
        error: function(data) {
          var response = data.responseJSON;
          if (data.status === 422) {
            STE.UI.Popups.notify('notification', 'error', Lang.get('labels.generic_error_validation'));

            _showValidationErrors($form, response.errors);
            if (response.onValidationError) {
              var fns = Object.keys(response.onValidationError);
              for (var i = 0; i < fns.length; i++) {
                var fn = STE.getGlobalFunction(fns[i]);
                if (typeof (fn) === 'function') {
                  fn.apply(window, response.onValidationError[fns[i]]);
                }
              }
            }
          } else {
            var context = (response && response.context) || 'notification';
            var alertType = (response && response.type) || 'error';
            var title = (response && response.title) || '';
            var message = (response && response.message) || Lang.get('labels.error_generic');

            STE.UI.Popups.notify(context, alertType, message, title);
          }
        },
        complete: function(data, status) {
          $submit.button('reset');
          $.unblockUI();

          var response = data.responseJSON;
          if (response) {
            if (config && config.onComplete && typeof config.onComplete === 'function') {
              config.onComplete(response);
            } else {

              if (afterSubmitFn && typeof window[afterSubmitFn] === 'function') {
                window[afterSubmitFn](response, status);
              }

              if (response.afterSubmitFn && typeof (fn = STE.getGlobalFunction(response.afterSubmitFn)) === 'function') {
                fn(response, status);
              }

              if (response.redirect) {
                var timeout = response.redirect_after || 500;
                setTimeout(function() {
                  if (response.redirect === window.location.href) {
                    if (response.targetTop && STE.UI.insideIFrame()) {
                      //top.location.reload();
                      top.postMessage('[TraghettiPer]' + JSON.stringify({
                        'subject': 'container',
                        'action': 'reload'
                      }), '*');
                    } else {
                      window.location.reload();
                    }
                  } else {
                    if (response.targetTop && STE.UI.insideIFrame()) {
                      top.postMessage('[TraghettiPer]' + JSON.stringify({
                        'subject': 'container',
                        'action': response.replace ? 'replace' : 'assign',
                        'url': response.redirect
                      }), '*');
                      //top.location[response.replace ? 'replace' : 'assign'](response.redirect);
                    } else {
                      window.location[response.replace ? 'replace' : 'assign'](response.redirect);
                    }
                  }
                }, timeout);
              }
            }
          }
        }
      });
    });
  };

  this.setSelectValue = function($select, value) {
    if ($select.find('option[value="' + value + '"]').length) $select.val(value);
  };

  var _parseWithParams = function($element) {
    var params = {};
    $.each($element.get(0).attributes, function() {
      if (this.specified) {
        if (this.name.startsWith('data-withfield-')) {
          var pname = this.name.substring('data-withfield-'.length);
          var pselector = $element.attr(this.name);
          var $pelement = $(pselector);
          if ($pelement.length > 0) {
            params[pname] = $pelement.val();
          }
        } else if (this.name.startsWith('data-with-')) {
          params[this.name.substring('data-with-'.length)] = this.value;
        }
      }
    });
    return params;
  };
};

STE.UI.Popups = new function() {
  /*var _colors = {
      default: '#ffffff',
      info: '#5bc0de',
      success: '#5cb85c',
      danger: '#d9534f',
      error: '#d9534f',
      warning: '#f0ad4e'
  };*/

  var _validClasses = ['default', 'success', 'error', 'danger', 'warning', 'info'];

  var _alert = null,
      _confirm = null,
      _modal = null,
      _notice = null,
      _map = null;

  var _getValidType = function(type) {
    type = type || 'info';
    if (_validClasses.indexOf(type) < 0) {
      type = 'info';
    }
    return type;
  };

  var _getCenterOffsetTop = function(height) {
    var iframeInfo = STE.getIFrameContainerInfo();
    if (iframeInfo) {
      var marginTop = (iframeInfo.clientHeight / 2 - height / 2 + (iframeInfo.scrollTop - iframeInfo.offsetTop));
      if (marginTop + height > iframeInfo.iframeHeight - 10) {
        do {
          marginTop -= 10;
        } while (marginTop + height > iframeInfo.iframeHeight - 10);
      }

      if (marginTop < 10) marginTop = 10;
      return marginTop;
    }
  };

  this.notify = function(context, type, message, title, onClose) {
    context = context || 'notification';
    type = type || 'message';
    title = title || '&nbsp;';
    message = message || '';
    onClose = onClose || function() {
    };

    switch (context) {
      case 'notification':
        this.notification(message, type);
        break;
      case 'dialog':
        this.alert(title, message, type, onClose);
        break;
      default:
        this.alert(title, message, type, onClose);
        break;
    }
  };

  this.notification = function(message, type) {
    type = _getValidType(type);

    var colors = {
      default: 'white',
      danger: 'red',
      error: 'red',
      info: 'blue',
      success: 'green',
      warning: 'yellow'
    };

    var iframeInfo = STE.getIFrameContainerInfo();
    var offsetY = 0;
    if (iframeInfo) {
      offsetY = iframeInfo.scrollTop + 10;
      if (offsetY < 10) offsetY = 10;
    }

    var options = {
      content: message,
      color: colors[type],
      offset: {
        y: offsetY
      },
      position: {
        x: 'center'
      },
      delayClose: 2000,
      stack: true,
      showCountdown: true
    };

    _notice = new jBox('Notice', options);
    return _notice.open();
  };

  this.confirm = function(title, message, onOk, onCancel, okLabel, cancelLabel, positionTop) {
    onOk = typeof onOk === 'function' ? onOk : function() {};
    onCancel = typeof onCancel === 'function' ? onCancel : function() {
      this.close();
    };

    okLabel = okLabel || 'OK';
    cancelLabel = cancelLabel || Lang.get('labels.generic_cancel');

    var width = 730;
    if (window.innerWidth < 730) {
      width = (window.innerWidth * 0.85);
    }

    var options = {
      closeOnClick: 'overlay',
      closeOnEsc: true,
      width: width,
      maxWidth: '100%',
      responsiveWidth: true,
      responsiveHeight: true,
      closeButton: 'title',
      confirmButton: okLabel,
      cancelButton: cancelLabel,
      confirm: onOk,
      cancel: onCancel,
      closeOnConfirm: true,
      title: title,
      content: message,
      onPosition: function() {
        var iframeInfo = STE.getIFrameContainerInfo();
        if (iframeInfo) {
          var $dialog = $(this.wrapper);

          var marginTop = positionTop !== undefined ? 40 : (iframeInfo.clientHeight / 2 - $dialog.outerHeight() +
              (iframeInfo.scrollTop - iframeInfo.offsetTop));
          if (marginTop < 10) marginTop = 10;

          if (marginTop + $dialog.outerHeight() > iframeInfo.iframeHeight) {
            marginTop -= $dialog.outerHeight();
          }
          $dialog.css('top', marginTop + 'px');
        }
      }
    };

    _confirm = new jBox('Confirm', options);
    _confirm.open();
    return _confirm;
  };

  this.alert = function(title, message, type, onOk) {
    type = _getValidType(type);
    onOk = typeof onOk === 'function' ? onOk : function() {
    };

    var width = 730;
    if (window.innerWidth < 730) {
      width = (window.innerWidth * 0.85);
    }

    var content = '<div class="alert alert-' + type + '">' + message + '</div>';
    var options = {
      closeOnClick: 'overlay',
      closeOnEsc: true,
      width: width,
      maxWidth: '100%',
      responsiveWidth: true,
      responsiveHeight: true,
      closeButton: 'title',
      confirmButton: 'OK',
      confirm: onOk,
      onClose: onOk,
      closeOnConfirm: true,
      title: title,
      content: content,
      onPosition: function() {
        var $dialog = $(this.wrapper);
        if (STE.UI.insideIFrame()) {
          var top = _getCenterOffsetTop($dialog.outerHeight());
          if (top) {
            $dialog.css('top', top + 'px');
          }
        }
        $dialog.find('.jBox-Confirm-button-cancel').css('visibility', 'hidden');
      }
    };

    _alert = new jBox('Confirm', options);
    return _alert.open();
  };

  this.ajaxDialog = function(endpoint, params) {
    var options = {
      closeOnClick: 'overlay',
      closeOnEsc: true,
      minWidth: '40%',
      maxWidth: window.innerWidth < 730 ? '90%' : '50%',
      responsiveWidth: true,
      responsiveHeight: true,
      closeButton: 'title',
      ajax: {
        url: endpoint,
        data: params,
        reload: 'strict',
        setContent: false,
        success: function(data) {
          if (data) {
            if (data.title) this.setTitle(data.title);
            if (data.content) {
              this.setContent(data.content);
            } else {
              this.setContent(data);
            }
          }
        }
      },
      onPosition: function() {
        var $dialog = $(this.wrapper);
        if (STE.UI.insideIFrame()) {
          var top = _getCenterOffsetTop($dialog.outerHeight());
          if (top) {
            $dialog.css('top', top + 'px');
          }
        }
      }
    };

    _modal = new jBox('Modal', options);
    return _modal.open();
  };

  this.mapDialog = function(map, onClose) {
    var height;
    var iframeInfo = STE.getIFrameContainerInfo();
    if (iframeInfo) {
      height = (Math.min(iframeInfo.clientHeight, iframeInfo.iframeHeight) * 0.6 - 51);
    } else {
      height = (document.documentElement.clientHeight * 0.8 - 51);
    }

    var width = 1100;
    if (window.innerWidth < 1100) {
      width = (window.innerWidth * 0.9);
    }

    var options = {
      closeOnClick: 'overlay',
      closeOnEsc: true,
      width: width,
      maxWidth: width,
      height: height,
      maxHeight: height,
      responsiveWidth: true,
      responsiveHeight: true,
      closeButton: 'title',
      title: Lang.get('labels.map_dialog_title'),
      onOpen: function() {
        var $mapContainer = $('<div />', {
          css: {
            width: width,
            height: height
          }
        });
        this.setContent($mapContainer);
        var $parent = $mapContainer.parent('.jBox-content');
        $parent.css({
          padding: 0,
          overflow: 'hidden'
        });
        var canvas = map.getCanvas();

        canvas.data({
          class: canvas.attr('class')
        }).attr('class', 'map').css('height', height).appendTo($mapContainer);

        map.recenterResize().enableControls(true);
      },
      onClose: function() {
        var canvas = map.getCanvas();
        canvas.attr('class', canvas.data('class')).css('height', canvas.data('height')).prependTo($('#map-panel'));

        map.recenterResize().enableControls(false);

        if (typeof onClose === 'function') {
          onClose();
        }
      },
      onPosition: function() {
        var $dialog = $(this.wrapper);
        if (STE.UI.insideIFrame()) {
          var top = _getCenterOffsetTop($dialog.outerHeight());
          if (top) {
            $dialog.css('top', top + 'px');
          }
        }
      }
    };

    if (!_map) {
      _map = new jBox('Modal', options);
      return _map.open();
    }
    return _map.open(options);
  };
};
