/**
 * Created by Carlo on 07/04/2016.
 */
var STE = new function () {
    var _loadEvents = [];

    var _iframeContainerInfo = null;

    this.addInit = function (fn) {
        _loadEvents.push(fn);
    };

    this.onInit = function () {
        if ('parentIFrame' in window) {
            setTimeout(function () {
                parentIFrame.getPageInfo(function (info) {
                    _iframeContainerInfo = info;
                });
            }, 1000);
        }

        $('body').tooltip({
            selector: '[data-toggle="tooltip"]',
            container: 'body'
        }).popover({
            selector: '[data-toggle="popover"]',
            container: 'body',
            trigger: 'hover'
        });

        STE.UI.chainedDropdowns();
        STE.UI.mirrorFields();
        STE.UI.actions();
        STE.UI.selectorTables();

        STE.UI.inputGroupClick();

        $('.async-load').each(function () {
            STE.UI.asyncLoad($(this));
        });

        $('[data-help]').each(function () {
            STE.UI.inputHelp($(this));
        });

        $('a[data-toggle="dialog"]').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            var endpoint = $this.attr('href');
            STE.UI.Popups.ajaxDialog(endpoint, {});
        });

        $('form.async').on('submit', function (e) {
            e.preventDefault();
            STE.UI.asyncFormSubmit($(this));
        });

        _loadEvents.forEach(function (fn) {
            fn();
        });
    };

    this.memoize = function (fn) {
        return function () {
            var args = Array.prototype.slice.call(arguments),
                hash = "",
                i = args.length;
            var currentArg = null;
            while (i--) {
                currentArg = args[i];
                hash += (currentArg === Object(currentArg)) ? JSON.stringify(currentArg) : currentArg;
                fn.memoize || (fn.memoize = {});
            }
            return (hash in fn.memoize) ? fn.memoize[hash] : fn.memoize[hash] = fn.apply(this, args);
        };
    };

    this.debounce = function (fn, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) fn.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) fn.apply(context, args);
        };
    };

    this.getLocale = function () {
        return $('html').attr('lang');
    };

    this.getGlobalFunction = function (name) {
        var obj = window;
        try {
            var parts = name.split('.');
            for (var i = 0, length = parts.length; i < length; ++i) {
                obj = obj[parts[i]];
            }
        } catch (e) {
            return null;
        }
        return obj;
    };

    this.getIFrameContainerInfo = function () {
        return _iframeContainerInfo;
    };
};
